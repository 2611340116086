import Vue from 'vue'
import VueRouter from 'vue-router'
import EnrollmentView from '../views/EnrollmentView.vue'
import ConfirmationView from "../views/ConfirmationView";
import MaintenancePage from "../views/MaintenancePage";

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: EnrollmentView,
    beforeEnter: (to, from, next) => {
      if(process.env.VUE_APP_MAINTENANCE==='true'){
        next('/maintenance')
      }
      next()
    },
  },
  {
    path: '/confirmations',
    name: 'confirmations',
    component: ConfirmationView,
    beforeEnter: (to, from, next) => {
      if(process.env.VUE_APP_MAINTENANCE==='true'){
        next('/maintenance')
      }
      next()
    },
  },
  {
    path:'/maintenance',
    name:'maintenance',
    component: MaintenancePage,
    beforeEnter: (to, from, next) => {
      if(process.env.VUE_APP_MAINTENANCE==='false'){
        next('/home')
      }
      next()
    }
  },
  {
    path: '*',
    redirect: '/'
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
