<template>
  <div class="main">
    <div class="left">

    </div>
    <div class="right">

      <v-container
          class="form"
      >

        <img src="../assets/logo-cuanza.svg" class="logo-cuanza">

        <div class="separator">&nbsp;</div>
        <v-container
            class="form-subtitle"
        >
          <h2>{{$t('BasicData')}}</h2>
          <div class="sub-info">{{$t('EnterApplicantInformation')}}</div>
        </v-container>

      <v-form
        ref="form"
        class="build-context"
        lazy-validation
        v-model="formValid"
        >
        <v-container
            v-for="[index, [attribute]] of Object.entries(Object.entries(form))"
            class="form-content"
            v-bind:key="index+attribute"
            v-bind:class="{'full-width': form[attribute].fullSize ===true, 'half-width': form[attribute].fullSize ===false}"
        >

          <v-text-field
              v-if="form[attribute].type === 'string'"
              :label="form[attribute].label"
              v-model="form[attribute].value"
              :rules="form[attribute].rules"
              :maxlength = "form[attribute].length"
              :filled="form[attribute].filled"
          >
          </v-text-field>

        </v-container>

        <div
            v-if="automaticSpacer"
            class="extra-spacer"
        >&nbsp;</div>

        <v-container
            class="form-content"
        >
          <div class="separator">&nbsp;</div>
          <h2>{{$t('Documentation')}}</h2>
          <div class="sub-info">{{$t('EnterApplicantDocumentation')}}</div>
        </v-container>

        <div
            v-for="[index, [attribute]] of Object.entries(Object.entries(files.value))"
            class="form-content"
            v-bind:class="{'full-width': files.value[attribute].fullSize ===true, 'half-width': files.value[attribute].fullSize ===false}"
            v-bind:key="attribute+index"
        >
          <v-file-input
              v-model="files.value[attribute].value"
              :multiple="files.value[attribute].multiple"
              :chips="files.value[attribute].chips"
              :small-chips="files.value[attribute].smallChips"
              :rules="files.value[attribute].rules"
              :label="files.value[attribute].label"
              :accept="files.value[attribute].accept"
              :placeholder="files.value[attribute].placeholder"
              :filled="files.value[attribute].filled"
          ></v-file-input>

        </div>

      </v-form>

      <v-row
          class="form-buttons pb-8 me-2"
          align="center"
          justify="end"
      >
        <v-btn
            depressed
            @click="reset"
        >
          {{$t('Clear')}}
        </v-btn>
        <v-btn
            class="ms-3"
            depressed
            :disabled="uploading"
            color="secondary"
            @click="sendForm"
        >
          {{$t('Send')}}
        </v-btn>
      </v-row>

      <v-snackbar
          v-model="successSnackbar"
          :multi-line="false"
      >
        {{ $t('FormSaveSuccessfully') }}

        <template v-slot:action="{ attrs }">
          <v-btn
              color="red"
              text
              v-bind="attrs"
              @click="successSnackbar = false"
          >
            {{ $t('Close') }}
          </v-btn>
        </template>
      </v-snackbar>
      <v-snackbar
          v-model="errorSnackbar"
          :multi-line="true"
      >
        {{ $t('FormSaveError') }}

        <template v-slot:action="{ attrs }">
          <v-btn
              color="red"
              text
              v-bind="attrs"
              @click="errorSnackbar = false"
          >
            {{ $t('Close') }}
          </v-btn>
        </template>
      </v-snackbar>
    </v-container>
    </div>
  </div>

</template>

<style scoped lang="scss">
@media(min-width: 1300px){
  .right{
    margin-left: 20px;
    background-color: #FFFFFF;

    .logo-cuanza{
      width: 350px;
    }

    .separator{
      height: 1px;
      margin: 14px 0px 24px 0;
      background-color: #E0E0E0;
    }

    .form-subtitle{
      padding-top: 0;
      padding-bottom: 0;
    }

    .sub-info{
      font-size: 16px;
      color: #9E9E9E;
      margin-bottom: 24px;
    }

    .form{
      overflow: scroll;
      overflow-x: hidden;
      height: calc(100vh - 88px);
      padding: 0 64px;
      padding-top: 24px;

      form-subtitle{
        padding-top: 0;
        padding-bottom: 0;
      }

      .form-title{
        font-size: 2rem;
        font-weight: bold;
        color: #000;
      }
      .build-context{
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;

        .extra-spacer{
          flex: 1;
          width: 100%;
          height: 2px;
        }

        .form-content{
          padding: 0 12px;
          margin-bottom: 1rem;

          .form-checkbox-row{
            padding: 0 12px;
          }
        }
        .full-width{
          width: 100%;
        }
        .half-width{
          width: 50%;
          margin-left: inherit;
        }
      }
      .form-buttons{
        display: flex;
        justify-content: flex-end;
        padding-top: 0;

      }
    }
  }
}

@media(max-width: 1300px){
  .right{
    margin-left: 20px;
    background-color: #FFFFFF;

    .logo-cuanza{
      width: 350px;
    }

    .separator{
      height: 1px;
      margin: 14px 0px 24px 0;
      background-color: #E0E0E0;
    }

    .form-subtitle{
      padding-top: 0;
      padding-bottom: 0;
    }

    .sub-info{
      font-size: 16px;
      color: #9E9E9E;
      margin-bottom: 24px;
    }

    .form{
      overflow: scroll;
      overflow-x: hidden;
      height: calc(100vh - 88px);
      padding: 24px;

      form-subtitle{
        padding-top: 0;
        padding-bottom: 0;
      }

      .form-title{
        font-size: 2rem;
        font-weight: bold;
        color: #000;
      }
      .build-context{
        display: block;


        .extra-spacer{
          flex: 1;
          width: 100%;
          height: 2px;
        }

        .form-content{
          padding: 0 12px;
          margin-bottom: 1rem;

          .form-checkbox-row{
            padding: 0 12px;
          }
        }
        .full-width{
          width: 100%;
        }
        .half-width{
          width: 100%;
          margin-left: inherit;
        }
      }
      .form-buttons{
        display: flex;
        justify-content: flex-end;
        padding-top: 0;

      }
    }
  }
}


@media(max-width: 800px){
  .right{
    margin: 10px;
    background-color: #FFFFFF;

    .logo-cuanza{
      width: 300px;
    }

    .separator{
      height: 1px;
      background-color: #E0E0E0;
    }

    .form{
      padding: 0 ;

      .build-context{
        display: block;
        .extra-spacer{
          visibility: collapse;
          content-visibility: hidden;
        }
      }
    }
  }

}

</style>

<script>
import _ from 'lodash';
import Repository from "@/repositories/RepositoryFactory";
const FormRepository = Repository.get("FormRepository");

export default {
  name: 'HomeView',

  computed:{

    lastKey(){
      return Object.keys(this.form).pop();
    },

    formValues(){
      let formValues = {};

      for(let [key, value] of Object.entries(this.form))
        formValues[key] = value.value;

      return formValues;
    },

    automaticSpacer(){
      let fullSizeObjects = _.filter(this.form, (value) => value.fullSize === true);
      let fullSizeObjectsCount = fullSizeObjects.length;
      let modCountValue = Object.keys(this.form).length - fullSizeObjectsCount;

      return (modCountValue % 2 === 1) && this.form[this.lastKey].fullSize === false;
    }

  },
  data() {
    return {
      maxFileSize: 10000000,
      form:{
        name:{
          type: 'string',
          label: this.$t('Name'),
          value: '',
          rules:[
            value => !!value || this.$t('Required'),
            value => {
              const regex = /^[a-zA-ZÀ-ÖØ-öø-ÿ\s]+$/;
              return regex.test(value) || this.$t('OnlyLetters');
            },value => (value &&  value.length <= 50) || this.$t('MaxCharacters'),
          ],
          length:"51",
          filled:true,
          fullSize: false,
        },

        surname:{
          type: 'string',
          label: this.$t('Surname'),
          value: '',
          rules:[
            value => !!value || this.$t('Required'),
              value => {
                const regex = /^[a-zA-ZÀ-ÖØ-öø-ÿ\s]+$/;
                return regex.test(value) || this.$t('OnlyLetters');
              },
            value => (value &&  value.length <= 50) || this.$t('MaxCharacters'),
          ],
          length:"51",
          filled:true,
          fullSize:false
        },

        email:{
          type: 'string',
          label: this.$t('Email'),
          value: '',
          rules:[
            value => !!value || this.$t('Required'),
            value => {
              const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
              return pattern.test(value) || this.$t('InvalidEmail')
            },
            value => (value &&  value.length <= 50) || this.$t('MaxCharacters'),
          ],
          length:"50",
          filled:true,
          fullSize:false
        },

        land_line:{
          type: 'string',
          label: this.$t('LandLine'),
          value: '',
          rules:[
            value => !!value || this.$t('Required'),
              value => {
                const pattern = /^[0-9]{6,}$/
                return pattern.test(value) || this.$t('InvalidLandLine')
              },
            value => (value &&  value.length <= 20) || this.$t('MaxCharacters'),
          ],
          length:"21",
          filled:true,
          fullSize:false
        },

        mobile_number:{
          type: 'string',
          label: this.$t('PhoneNumber')+ ' ' +this.$t('Opcional'),
          value: '',
          rules:[
            value => {
              const pattern = /^[0-9]{6,}$/

              return value.length >0 ? pattern.test(value) || this.$t('InvalidPhoneNumber') : true;
            },
          ],
          length:"20",
          filled:true,
          fullSize:false
        },

      },

      files:{
        type: 'files',
        value: {
          ENROLLMENT_FORM: {
            label: this.$t('RegisterForm') + ' ' +this.$t('PDF'),
            multiple: false,
            chips: false,
            smallChips: false,
            accept: 'application/pdf',
            placeholder: this.$t('PickFile'),
            rules:[
              value => !!value || this.$t('Required'),
              value => !value || value.size < this.maxFileSize || this.$t('MaximumFileSize10MB'),
              value => {
                const pattern = /^application\/(pdf)$/

                return value != null
                    ? (pattern.test(value.type) || this.$t('InvalidFileType'))
                    : true;
              },
            ],
            value:null,
            filled:true,
            fullSize:false
          },

          PAYMENT_RECEIPT: {
            label: this.$t('TuitionPaymentReceipt')+ ' ' + this.$t('PDF'),
            multiple: false,
            chips: false,
            smallChips: false,
            accept: 'application/pdf',
            placeholder: this.$t('PickFile'),
            rules:[
              value => !!value || this.$t('Required'),
              value => !value || value.size < this.maxFileSize || this.$t('MaximumFileSize10MB'),
              value => {
                const pattern = /^application\/(pdf)$/

                return value != null
                    ? (pattern.test(value.type) || this.$t('InvalidFileType'))
                    : true;
              },
            ],
            value:null,
            filled:true,
            fullSize:false
          },

          BI: {
            label: this.$t('IdentityDocumentPassport')+ ' ' + this.$t('PDF'),
            multiple: false,
            chips: false,
            smallChips: false,
            accept: 'application/pdf',
            placeholder: this.$t('PickFile'),
            rules:[
              value => !!value || this.$t('Required'),
              value => !value || value.size < this.maxFileSize || this.$t('MaximumFileSize10MB'),
              value => {
                const pattern = /^application\/(pdf)$/

                return value != null
                    ? (pattern.test(value.type) || this.$t('InvalidFileType'))
                    : true;
              },
            ],
            value:null,
            filled:true,
            fullSize:false
          },

          ACADEMIC_STANDARDS: {
            label: this.$t('UniversityAcademicStandardsDocument')+ ' ' +this.$t('PDF') ,
            multiple: false,
            chips: false,
            smallChips: false,
            accept: 'application/pdf',
            placeholder: this.$t('PickFile'),
            rules:[
              value => !!value || this.$t('Required'),
              value => !value || value.size < this.maxFileSize || this.$t('MaximumFileSize10MB'),
              value => {
                const pattern = /^application\/(pdf)$/

                return value != null
                    ? (pattern.test(value.type) || this.$t('InvalidFileType'))
                    : true;
              },
            ],
            value:null,
            filled:true,
            fullSize:false
          },
        } ,
      },

      menu:false,
      formValid:false,

      successSnackbar:false,
      errorSnackbar:false,

      uploading:false,

    }
  },

  methods:{
    validate () {
      return this.$refs.form.validate()
    },

    reset () {
      this.$refs.form.resetValidation()
      for(let [key] of Object.entries(this.form)){

        if(this.form[key].type ==='select'){
          this.form[key].value = this.form[key].items[0];
          continue;
        }

        Array.isArray(this.form[key].value)
            ? this.form[key].value = []
            : this.form[key].value = '';

      }

      for (let [key] of Object.entries(this.files.value))
        this.files.value[key].value = null;

    },

    async postFormData() {
      const formData = new FormData();
      let position = 0;
      let valid = false;

      for (let [key, value] of Object.entries(this.formValues))
        formData.append(key, value);

      for (let [key, typeObject] of Object.entries(this.files.value)) {

        let documents = typeObject.value;

        if (!Array.isArray(documents) && typeof documents === 'object') {
          valid = true
          formData.append(`documents[${position}][type]`, key);
          formData.append(`documents[${position}][file]`, documents);
        }

        position += 1;
      }

      if (valid) {
        await FormRepository.postForm(
            process.env.VUE_APP_POST_URL_ENRROLLMENT,
            formData,
            {'Content-Type': `multipart/form-data`,}
        ).then(() => {
          this.successSnackbar = true;
          this.reset();
        })
            .catch(() => {
              this.errorSnackbar = true;
            });
      }
    },


    async sendForm() {
      this.uploading = true;

      if (this.validate())
        await this.postFormData();

      this.uploading = false;
    }
  }
}
</script>
