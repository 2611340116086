import Vue from 'vue';
import VueI18n from 'vue-i18n';


Vue.use(VueI18n);

const portugueseTranslate = require('../languages/pt.json');
const spanishTranslate = require('../languages/es.json');
const messages = { 'pt': portugueseTranslate, 'es': spanishTranslate };
const i18n = new VueI18n({ locale: 'pt', fallbackLocale: 'es', messages, });

export default i18n;