<template>
  <div class="maintenance">
    <div class="image">
      <img class="img-v" src="../assets/maintenance-gray.svg">
    </div>
    <div class="maintenance-tittle">
      <div class="maintenance-tittle-text">{{$t('Maintenance')}}</div>
    </div>
    <div class="maintenance-message">
      <div class="maintenance-message-text">{{$t('MaintenanceMessage')}}</div>
    </div>
  </div>
</template>

<script>


export default {
  name: "MaintenancePage",

}
</script>

<style scoped lang="scss">

.maintenance {
  height: 40vh;
  margin: 15% 0;
  font-size: 30px;
  text-align: center;
  font-weight: 300;

  .image{
    margin: auto;
    height: 200px;
    width: 200px;
    border-radius: 50%;
    background-color: #CC1719;

    .img-v{
      margin-top: 40px;
      height: 120px;
      width: 120px;
    }

  }

  .maintenance-tittle{
    margin-top: 30px;
    font-size: 40px;
  }
}
</style>
