<template>
  <v-app>
    <div>

      <v-app-bar v-if="$router.history.current['path'] =='/'"
          color="#424242"
          dense
          dark
      >
        <v-toolbar-title>{{$t('TitleEnrollments')}}</v-toolbar-title>

      </v-app-bar>

      <v-app-bar v-if="$router.history.current['path'] =='/confirmations'"
                 color="#424242"
                 dense
                 dark
      >
        <v-toolbar-title>{{$t('TitleConfirmations')}}</v-toolbar-title>

      </v-app-bar>
    </div>
    <div
        class="form-container"
    >
      <router-view/>
    </div>

  </v-app>
</template>

<style lang="scss">
html { overflow-y: auto }

.v-toolbar__title{
  font-size: 1.15rem;
}

@media screen and (max-width: 800px){
  .main{
    margin: 0px;
    .left {
      visibility: collapse;
      content-visibility: hidden;
    }

    .right {
      grid-area: 1 / 2 / 2 / 4;


    }

  }
}
@media screen and (min-width: 800px) {
  .form-container{
    background-color: #ECEEEC;
  }
  .main {
    margin: 20px;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: 1fr;
    grid-column-gap: 0px;
    grid-row-gap: 0px;

    .left {
      height: calc(100vh - 88px);
      background-image: url(../src/assets/background-UNIC.jpg);
      background-repeat: no-repeat;
      background-position: center center;
      background-size: cover;
      border-radius: 5px;

    }

    .right {
      border-radius: 5px;
      grid-area: 1 / 2 / 2 / 4;
    }

  }
}


</style>

<script>

export default {
  name: 'App',

  data: () => ({
    //
  }),
};
</script>
